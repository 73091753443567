import Navbar from 'components/navbar/navbar';
import './App.css';
import Projects from 'pages/projects/projects';
import HomePage from 'pages/home/home';
import Journey from 'pages/journey/journey';

function App() {
  return (
    <div className="Container">
 <header>
        <Navbar className="sticky-navbar" />
      </header>      <div id='Home'>
        <HomePage />
      </div>
      <div id='Journey' className='full-screen'>
        <Journey />
      </div>
      <div id='Projects' className='full-screen'>
        <Projects />
      </div>
     
    </div>
  );
}
export default App;