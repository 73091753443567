import AutoTypeEraseText from "components/type_erase_text/type_erase_text";
import "./home.css"
import logo from "assets/images/homepage_icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedinIn } from "@fortawesome/free-brands-svg-icons";


const HomePage = () => {
    return (
        <div className="home-container lg:flex block items-center">
            <div className="left-container lg:w-1/2 ">
                <p className="greeting-text">&lt; Hello World /&gt; </p>
                <h1 className="personal-intro">Hi, I'm <span>Harsha Vardhan</span></h1>
                <h3 className="role-descritpion">a <span className="fixed-width"><AutoTypeEraseText text={["Flutter Developer,", "Full Stack Developer,", "Backend Developer."]} /></span></h3>
                <p className="bio-intro">Experienced Software Engineer specializing in scalable web applications with 5 years of expertise. Proficient in ASP.NET Core, React, and Flutter, enhancing user engagement. Skilled in CI/CD implementation, database optimization, and adhering to best practices. Collaborative team player with a Masters in Artificial Intelligence, adept at mentoring and delivering innovative solutions.</p>
                <div className="connect-container lg:flex">
                    <div className="social-container lg:w-1/2">
                        <p className="subheading">
                            Connect with me
                        </p>
                        <ul className="social-icons">
                            <li className="social-icon"><a href="https://www.linkedin.com/in/harsha-vardhan-gangineni/"><FontAwesomeIcon id="socialIcon" size="xl" icon={faLinkedinIn}  /></a></li>
                        </ul>
                    </div>
                    <div className="work-container lg:w-1/2">
                        <div className="social-container flex-1">
                            <p className="subheading">
                               Check out my work
                            </p>
                            <ul className="social-icons">
                                <li className="social-icon"><a href="https://github.com/coprayaan"><FontAwesomeIcon id="socialIcon" size="xl" icon={faGithub} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="right-container lg:w-1/2">
                <img src={logo} alt="Harsha Vardhan" />
            </div>

        </div>
    )
}
export default HomePage;