import "./journey.css"



const Journey = () => {

    return (
        <div className="journey-container">
            <p className="exp-summary">
                5+ years of experience
            </p>
            <h1 className="journey-heading">
                Journey
            </h1>
            <div className="journey-content relative">
                <div className="dot absolute transform -translate-x-1/2" id="firstDot">
                    <div className="rounded-full  w-4 h-4"></div>
                </div>
                <div className="absolute h-px bg-black" id="firstLine"></div>
                <div className="dot absolute transform -translate-x-1/2" id="secondDot">
                    <div className="rounded-full bg-black w-4 h-4"></div>
                </div>
                <div className="absolute h-px bg-black" id="secondLine"></div>

                <div className="dot absolute  transform -translate-x-1/2" id="thirdDot">
                    <div className="rounded-full bg-black w-4 h-4"></div>

                </div>
                <div className="absolute h-px bg-black" id="thirdLine"></div>

                <div className="dot absolute  transform -translate-x-1/2" id="fourthDot">
                    <div className="rounded-full bg-black w-4 h-4"></div>

                </div>
                <div className="absolute h-px bg-black" id="fourthLine"></div>

                <div className="flex flex-wrap">
                    <div className="company-card lg:w-1/2" id="btechCard">
                        <div className="content flex justify-between">
                            <div>
                                <p className="position text-xl font-bold">Bachelors in Computer Science </p>
                                <h2 className="company-name text-sm">KKR & KSR Instiute of Technology & Sciences</h2>
                            </div>
                            <div className="flex flex-wrap justify-between items-center">
                                <p className="year text-sec-color" >2014 - 2018</p>
                            </div>
                        </div>

                    </div>
                    <div className="lg:w-1/2">

                    </div>
                    <div className=" lg:w-1/2">

                    </div>
                    <div className="company-card lg:w-1/2">
                        <div className="content flex justify-between">
                            <div>
                                <p className="position text-xl font-bold">Software Engineer</p>
                                <h2 className="company-name text-sm">Technovert Solutions</h2>
                            </div>
                            <div className="flex flex-wrap justify-between items-center">
                                <p className="year text-sec-color" >2018 - 2021</p>
                            </div>
                        </div>

                    </div>
                    <div className="company-card lg:w-1/2">
                        <div className="content flex justify-between">
                            <div>
                                <p className="position text-xl font-bold">Masters in Artificial Intellignence </p>
                                <h2 className="company-name text-sm">University of North Texas</h2>
                            </div>
                            <div className="flex flex-wrap justify-between items-center">
                                <p className="year text-sec-color" >2021 - 2023</p>
                            </div>
                        </div>

                    </div>
                    <div className="lg:w-1/2">


                    </div>
                    <div className="lg:w-1/2">

                    </div>
                    <div className="company-card lg:w-1/2">
                        <div className="content flex justify-between">
                            <div>
                                <p className="position text-xl font-bold">Student Assistant</p>
                                <h2 className="company-name text-sm">University Of North Texas</h2>
                            </div>
                            <div className="flex flex-wrap justify-between items-center">
                                <p className="year text-sec-color" >2021 - 2023</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    );
}


export default Journey;