import "./projects.css"
import portfolioImage from '../../assets/images/portfolio.png';



const Projects = () => {

    return (
        <div className="projects-container">
            <p className="projects-intro">
                Visit my portfolio and kindly provide your feedback.
            </p>
            <h1 className="projects-heading">
                My Portfolio
            </h1>
            <div className="flex"> 
            <div className="flex-1">
                    <div className="project-card">
                        <img src={portfolioImage} alt="Project" />
                        <div className="content">
                        <p>Web </p>
                        <h2>Portfolio Site</h2>
                        </div>
                    </div>
                </div>
               
            </div>
        </div>
    );
}


export default Projects;