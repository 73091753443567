import React, { useState, useRef } from 'react';
import logo from "../../assets/icons/logo.svg";
import './navbar.css'; // Assuming you have CSS for styling

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navRef = useRef(null);

  const scrollTo = (event) => {
    // Your scroll to logic here
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuClick = () => {
    setIsMenuOpen(false);
  };

  return (
    <nav className="flex" ref={navRef}>
      <div className="Logo">
        <img src={logo} alt="Logo" />
      </div>

      <div className={`hamburger-icon ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <ul className={`flex  ${isMenuOpen ? 'open' : ''}`}>
        <li><a href="#Home" onClick={() => { scrollTo(); handleMenuClick(); }}>Home</a></li>
        <li><a href="#Journey" onClick={() => { scrollTo(); handleMenuClick(); }}>Journey</a></li>
        <li><a href="#Projects" onClick={() => { scrollTo(); handleMenuClick(); }}>Projects</a></li>
        <li><a href="#Contact" onClick={() => { scrollTo(); handleMenuClick(); }}>Hire me</a></li>
      </ul>
    </nav>
  );
}


export default Navbar;
